import React, { useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminInterventionsTable from './listing-table';
import AdminOverviewTable from './admin-over-veiw/listing-overview-table';
import '../admin-work-flow/rich-data-table/admin-dashboard.scss';
import AdminManageRole from './admin-manage-role/admin-manage-role';

import AdminManagePharmacy from './admin-manage-pharmacy/admin-manage-pharmacy';
import DailySummary from './../deo-work-flow/deo-listing-component/daily-summary';
import AdminStoreTotalTab from 'app/modules/admin-work-flow/store-total/store-total';
import PharmacistStatsTab from 'app/modules/admin-work-flow/pharmacist-stats/pharmacist-stats';
export const AdminDashboard = props => {
  const { state }: any = useLocation();
  const role = localStorage.getItem('role');
  const { sessionByPharmacyEntities, sessionCounts, sessionTypeValue, sessionStatusValue, rowsPer, pageNumber, isAdmin, isPharmacist } =
    props;
  const page = useAppSelector(sta => sta.session.pageNo);
  const rowsPerPage = useAppSelector(sta => sta.session.rows);
  const [showMenu, setShowMenu] = useState(false);
  const [index, setIndex] = useState(1);
  const [showOptions, setShowOptions] = useState(false);
  const [values, setValues] = useState({ service_id: 1, session_type: null, session_status: null });
  const [selectedColor, setSelectedColor] = useState(state ? state.selectedColor : 'one');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(sta => sta.authentication.account);
  const pharmacyName = localStorage.getItem('pharmacy_name');
  const [showadmin, setShowadmin] = useState(false);
  useEffect(() => {
    if (state) {
      switch (state.selectedColor) {
        case 'one':
          setIndex(1);
          break;
        case 'two':
          setIndex(2);
          break;
        case 'three':
          setIndex(3);
          break;
        case 'four':
          setIndex(4);
          break;
        case 'five':
          setIndex(5);
          break;
        case 'six':
          setIndex(6);
          break;
        default:
          setIndex(1);
      }
      // setSelectedColor(selectedColor);

      setSelectedColor(state.selectedColor);
      setIndex(state.index);
    }
  }, [state]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };
  const handleTabClick = (color, idx) => {
    setSelectedColor(color);
    setIndex(idx);
  };
  return (
    <Grid lg={12} container style={{ height: '92.3vh' }}>
      <Grid lg={2.3} xs={12} sm={3} container style={{ backgroundColor: '#325C92', justifyContent: 'center' }}>
        {showMenu === false ? (
          <Grid lg={10} xs={10} sm={10} style={{ marginTop: 30, height: '50%', backgroundColor: '' }}>
            <List
              aria-label="Sidebar"
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
                [`& .${listItemDecoratorClasses.root}`]: {
                  justifyContent: 'space-between',
                  pr: '18px',
                },
                '& [role="button"]': {
                  borderRadius: '0 2px 2px 0',
                },
                height: 300,
              }}
            >
              <ListItem
                onClick={() => {
                  handleTabClick('one', 1);
                }}
                style={{ height: 40 }}
              >
                <ListItemButton
                  data-index={1}
                  selected={index === 1}
                  style={{ backgroundColor: selectedColor === 'one' ? '#7CB6F8' : '#325C92' }}
                  onClick={() => {
                    setSelectedColor('one');
                  }}
                >
                  <img
                    data-index={1}
                    src={'../../../../content/assets/in-progress-svgrepo-com.png'}
                    alt="logo"
                    style={{ height: '19px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={1}
                    sx={{
                      fontWeight: '',
                      color: selectedColor === 'one' ? '#4877B1' : undefined,
                      fontSize: 13,
                      marginLeft: 0.7,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <span style={{ color: 'white' }} data-index={1}>
                      Summary
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>

              <ListItem
                onClick={() => {
                  handleTabClick('two', 2);
                }}
                style={{ height: 40 }}
              >
                <ListItemButton
                  data-index={2}
                  selected={index === 2}
                  style={{ backgroundColor: selectedColor === 'two' ? '#7CB6F8' : '#325C92' }}
                  onClick={() => {
                    setSelectedColor('two');
                  }}
                >
                  <img
                    data-index={2}
                    src={'../../../../content/assets/overview.png'}
                    alt="logo"
                    style={{ height: '19px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={2}
                    sx={{
                      fontWeight: '',
                      color: selectedColor === 'two' ? '#4877B1' : undefined,
                      fontSize: 13,
                      marginLeft: 0.7,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <span style={{ color: 'white' }} data-index={2}>
                      Stats
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>

              <Divider style={{ backgroundColor: 'lightgray', height: 1, marginTop: 0 }} />
              <ListItem
                onClick={() => {
                  handleTabClick('three', 3);
                }}
                style={{ height: 40 }}
              >
                <ListItemButton
                  data-index={3}
                  selected={index === 3}
                  style={{ backgroundColor: selectedColor === 'three' ? '#7CB6F8' : '#325C92' }}
                  onClick={() => {
                    setSelectedColor('three');
                  }}
                >
                  <img
                    data-index={3}
                    src={'../../../../content/assets/intervention.png'}
                    alt="logo"
                    style={{ height: '18px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={3}
                    sx={{
                      fontWeight: '',
                      color: selectedColor === 'three' ? '#4877B1' : undefined,
                      fontSize: 13,
                      marginLeft: 1,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <span style={{ color: 'white' }} data-index={3}>
                      Overview
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>

              <Divider style={{ backgroundColor: 'lightgray', height: 1, marginTop: 0 }} />
              <ListItem
                onClick={() => {
                  handleTabClick('four', 4);
                }}
                style={{ height: 40 }}
              >
                <ListItemButton
                  data-index={4}
                  selected={index === 4}
                  style={{ backgroundColor: selectedColor === 'four' ? '#7CB6F8' : '#325C92' }}
                  onClick={() => {
                    setSelectedColor('four');
                  }}
                >
                  <img
                    data-index={4}
                    src={'../../../../content/assets/profile3.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '18px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={4}
                    sx={{ fontWeight: '', color: selectedColor === 'four' ? '#7CB6F8' : '#325C92', fontSize: 13, marginLeft: 1 }}
                  >
                    <span style={{ color: 'white' }} data-index={4}>
                      Manage Role
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>

              <Divider style={{ backgroundColor: 'lightgray', height: 1, marginTop: 0 }} />
              <ListItem
                onClick={() => {
                  handleTabClick('five', 5);
                }}
                style={{ height: 40 }}
              >
                <ListItemButton
                  data-index={5}
                  selected={index === 5}
                  style={{ backgroundColor: selectedColor === 'five' ? '#7CB6F8' : '#325C92' }}
                  onClick={() => {
                    setSelectedColor('five');
                  }}
                >
                  <img
                    data-index={5}
                    src={'../../../../content/assets/managepharmacy.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '18px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={5}
                    sx={{ fontWeight: '', color: selectedColor === 'five' ? '#7CB6F8' : '#325C92', fontSize: 13, marginLeft: 1 }}
                  >
                    <span style={{ color: 'white' }} data-index={5}>
                      Manage Pharmacy
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <Divider style={{ backgroundColor: 'lightgray', height: 1, marginTop: 0 }} />
              <ListItem
                onClick={() => {
                  handleTabClick('six', 6);
                }}
                style={{ height: 40 }}
              >
                <ListItemButton
                  data-index={6}
                  selected={index === 6}
                  style={{ backgroundColor: selectedColor === 'six' ? '#7CB6F8' : '#325C92' }}
                  onClick={() => {
                    setSelectedColor('six');
                  }}
                >
                  <img
                    data-index={6}
                    src={'../../../../content/assets/managepharmacy.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '18px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={6}
                    sx={{ fontWeight: '', color: selectedColor === 'six' ? '#7CB6F8' : '#325C92', fontSize: 13, marginLeft: 1 }}
                  >
                    <span style={{ color: 'white' }} data-index={6}>
                      Store Total
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              {/*<Divider style={{ backgroundColor: 'lightgray', height: 1, marginTop: 0 }} />*/}
              {/*<ListItem*/}
              {/*  onClick={() => {*/}
              {/*    handleTabClick('seven', 7);*/}
              {/*  }}*/}
              {/*  style={{ height: 40 }}*/}
              {/*>*/}
              {/*  <ListItemButton*/}
              {/*    data-index={7}*/}
              {/*    selected={index === 7}*/}
              {/*    style={{ backgroundColor: selectedColor === 'seven' ? '#7CB6F8' : '#325C92' }}*/}
              {/*    onClick={() => {*/}
              {/*      setSelectedColor('seven');*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <img*/}
              {/*      data-index={7}*/}
              {/*      src={'../../../../content/assets/managepharmacy.png'} // Use the image URL from the array*/}
              {/*      alt="logo"*/}
              {/*      style={{ height: '18px', marginRight: 10, marginLeft: 20 }}*/}
              {/*    />*/}
              {/*    <ListItemContent*/}
              {/*      data-index={7}*/}
              {/*      sx={{ fontWeight: '', color: selectedColor === 'six' ? '#7CB6F8' : '#325C92', fontSize: 13, marginLeft: 1 }}*/}
              {/*    >*/}
              {/*      <span style={{ color: 'white' }} data-index={7}>*/}
              {/*        Pharmacist Stats*/}
              {/*      </span>*/}
              {/*    </ListItemContent>*/}
              {/*  </ListItemButton>*/}
              {/*</ListItem>*/}
            </List>

            <div className="logout_div">
              <img src={'../../../../content/assets/profile1.png'} alt="logo" style={{ height: '25px' }} onClick={() => {}} />

              <div
                style={{
                  justifyContent: 'space-between',
                  marginLeft: 5,
                  width: '70%',
                  overflow: 'auto',
                  alignSelf: 'center',
                  marginTop: 8,
                }}
              >
                <div>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
                <h1 className="logout_text"> {role === null || role === 'null' ? '' : role.replace(/"/g, '').replace(/^ROLE_/, '')}</h1>
                <h1 className="logout_text">{currentUser && currentUser['login']}</h1>
              </div>
              <IconButton
                onClick={handleClick}
                style={{
                  backgroundColor: '',
                  marginRight: 5,
                  height: 30,
                  width: 20,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/threedots.png'} alt="logo" style={{ height: '25px' }} />
              </IconButton>
            </div>
          </Grid>
        ) : (
          []
        )}
      </Grid>
      {index === 1 && selectedColor === 'one' && (
        <>
          <DailySummary />
        </>
      )}
      {index === 2 && selectedColor === 'two' && <AdminOverviewTable />}

      {index === 3 && selectedColor === 'three' && (
        <>
          <AdminInterventionsTable />
        </>
      )}
      {index === 4 && selectedColor === 'four' && (
        <>
          <AdminManageRole />
        </>
      )}
      {index === 5 && selectedColor === 'five' && (
        <>
          <AdminManagePharmacy />
        </>
      )}
      {index === 6 && selectedColor === 'six' && (
        <>
          <AdminStoreTotalTab />
        </>
      )}
      {index === 7 && selectedColor === 'seven' && (
        <>
          <PharmacistStatsTab />
        </>
      )}
    </Grid>
  );
};
export default AdminDashboard;
