import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import './login.scss';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { handleLocumLogin, logout } from 'app/shared/reducers/authentication';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import ListItem from '@mui/joy/ListItem';
import PersonIcon from '@mui/icons-material/Person';
import { DoDisturb, Done, KeyboardArrowRight, Tag } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LocumModal = props => {
  const { account } = useAppSelector(state => state.authentication);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState({ first_name: '', gphc_number: '' });
  const [fieldError, setFieldError] = useState({
    first_name: false,
    last_name: false,
    gphc_number: false,
  });
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const agreement = () => {
    setOpen(false);
    navigate('/');
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(logout());
    navigate('/login');
  };

  useEffect(() => {
    setValues(oldValues => ({
      ...oldValues,
      first_name: account && account.firstName,
      last_name: account && account.lastName,
    }));
    setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: true }));
  }, [account]);

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'first_name' || e.target.name === 'gphc_number') {
      checkSingleFieldValidations(e);
    }
  };
  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['first_name'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['first_name']: true }));
    }

    if (values['gphc_number'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: true }));
    }
  };

  const isAllowedToSubmit = () => {
    checkValidationsBeforeSubmit();
    if (
      !fieldError['first_name'] &&
      !fieldError['gphc_number'] &&
      values['first_name'] !== undefined &&
      values['gphc_number'] !== undefined
    ) {
      setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: false }));
      return true;
    } else {
      return false;
    }
  };

  const handleLocumLoginDetails = () => {
    if (isAllowedToSubmit() && account && account.id) {
      const appUserId = account.id;
      dispatch(handleLocumLogin(appUserId, values.first_name, null, values.gphc_number));
    }
  };
  useEffect(() => {
    if (localStorage.getItem('locumVerified')) {
      setOpen(true);
    } else {
      navigate('/login/gphc');
    }
  }, [localStorage.getItem('locumVerified')]);

  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleLocumLoginDetails();
    }
  };
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/login-bg-2.png')`, // Ensure the URL is correct
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        className={'form-background'}
        sx={{
          backgroundImage: `url('../../../content/images/shape-2.png')`, // Ensure the URL is correct
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
              <ArrowBackIcon onClick={logoutUser} />
            </Box>
            <img src={'../../../content/logos/final-logo.png'} alt="Logo" style={{ height: 150, width: 150, marginBottom: 24 }} />
            <Typography component="h1" fontWeight={'600'} variant="h6">
              Welcome, {values.first_name}
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Please enter your GHPC No.
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }} onKeyDown={handleKeyDown}>
              {/*<TextField*/}
              {/*  margin="normal"*/}
              {/*  required*/}
              {/*  fullWidth*/}
              {/*  name="firstname"*/}
              {/*  label="First Name"*/}
              {/*  value={values ? values.first_name : ''}*/}
              {/*  type="text"*/}
              {/*  error={fieldError['first_name']}*/}
              {/*  onChange={setFormValues}*/}
              {/*  id="firstname"*/}
              {/*  autoComplete="first_name"*/}
              {/*  InputLabelProps={{*/}
              {/*    shrink: true,*/}
              {/*  }}*/}
              {/*/>*/}
              <TextField
                margin="normal"
                required
                fullWidth
                autoFocus={true}
                name="gphc_number"
                placeholder="GHPC Number"
                error={fieldError['gphc_number']}
                onChange={setFormValues}
                type="text"
                id="gphcnumber"
                autoComplete="gphc_number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tag />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={'button-height'}
                sx={{ mt: 2, mb: 1, position: 'relative' }}
                onClick={handleLocumLoginDetails}
              >
                Continue
                <KeyboardArrowRight sx={{ position: 'absolute', right: 16 }} />
              </Button>
            </Box>
          </Box>
        </Container>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md" // Set a reasonable maxWidth, 'md' stands for medium
          fullWidth // Ensure the dialog uses full width
        >
          <DialogTitle sx={{ color: '#236EBA' }}>{'Instructions.'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <List>
                <ListItem>
                  <Typography className={'list-count'}>1. </Typography>{' '}
                  <ListItemText primary="Please familiarise yourself with the Pharmacy name and address prior to calling the patients" />
                </ListItem>
                <ListItem>
                  <Typography className={'list-count'}>2. </Typography>
                  <ListItemText primary=" I confirm I will adhere to NHS New Medicines Service Service specification" />
                </ListItem>
                <ListItem>
                  <Typography className={'list-count'}>3. </Typography>
                  <ListItemText primary=" I confirm I will adhere to Pharmacy Solutions SOP's" />
                </ListItem>
                <ListItem>
                  <Typography className={'list-count'}>4. </Typography>
                  <ListItemText primary=" I confirm I have appropriate Indemnity insurance in place to conduct the new medicines service" />
                </ListItem>
                <ListItem>
                  <Typography className={'list-count'}>5. </Typography>
                  <ListItemText primary=" I confirm I have conducted the CPPE training for the New Medicines Service consultation" />
                </ListItem>
                <ListItem>
                  <Typography className={'list-count'}>6. </Typography>
                  <ListItemText primary=" I confirm I will maintain patient confidentiality throughout my practice" />
                </ListItem>
                <ListItem>
                  <Typography className={'list-count'}>7. </Typography>
                  <ListItemText primary=" Any NMS I can conduct not in line with the service specification will not be paid" />
                </ListItem>
                <ListItem>
                  <Typography className={'list-count'}>8. </Typography>
                  <ListItemText primary=" Any NMS which are conducted fraudulently or do not adhere to the service specification will be reported" />
                </ListItem>
                <ListItem>
                  <Typography className={'list-count'}>9. </Typography>
                  <ListItemText primary=" I confirm I will write accurate and detailed intervention notes" />
                </ListItem>
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="error"
              sx={{
                backgroundColor: '#E90016',
                borderRadius: '2px', // make the button a perfect circle
                padding: '10px 20px',
                fontSize: 12,
                width: 150, // set the width of the button
                height: 40, // set the height of the button
                position: 'relative',
              }}
            >
              <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
              Decline
            </Button>
            <Button
              onClick={agreement}
              variant="contained"
              sx={{
                backgroundColor: '#45C12B',
                borderRadius: '2px', // make the button a perfect circle
                padding: '10px 20px',
                fontSize: 12,
                width: 150, // set the width of the button
                height: 40, // set the height of the button
                position: 'relative',
              }}
            >
              Accept
              <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default LocumModal;
